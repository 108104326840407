import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['reminderBeforeExpirationDates', 'hasExpirationDate', 'hasMissingFileRemind', 'missingFileRemindDates']

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    this.toggleExpirationReminder(this.hasExpirationDateTarget)
    this.toggleMissingFileReminder(this.hasMissingFileRemindTarget)
  }

  /* ----- ACTION EVENTS ----- */

  onHasExpirationDateChange(event) {
    this.toggleExpirationReminder(event.currentTarget)
  }

  onHasMissingFileRemindChange(event) {
    this.toggleMissingFileReminder(event.currentTarget)
  }

  /* ----- FUNCTIONS ----- */
  toggleExpirationReminder(hasExpirationDate) {
    if(hasExpirationDate.checked) {
      this.reminderBeforeExpirationDatesTarget.classList.remove('hide')
    } else {
      this.reminderBeforeExpirationDatesTarget.classList.add('hide')
    }
  }

  toggleMissingFileReminder(hasMissingFileRemind) {
    if(hasMissingFileRemind.checked) {
      this.missingFileRemindDatesTarget.classList.remove('hide')
    } else {
      this.missingFileRemindDatesTarget.classList.add('hide')
    }
  }

  /* ----- GETTERS / SETTERS ----- */
}
